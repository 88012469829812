import { FunctionComponent } from "react";
import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from "@mui/material";

import { useApi } from "../../../utils/hooks/useApi";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";
import { Search } from "../../../Types/Search";
import { getNodeById } from "../../../utils/tree";
import { useReferenceDataContext } from "../../../utils/contexts/ReferenceDataContext";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { paths } from "../../../app/paths";
import { FinndoffUser } from "../../../utils/contexts/UserContext";
import { config } from "../../../config";
/*
const TypographyNoWrap = withStyles({
  root: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
})(Typography);
*/

type SearchCardProps = {
  search: Search;
  finndoffUser: FinndoffUser;
  onDelete: (search: Search) => void;
};

export const SearchCard: FunctionComponent<SearchCardProps> = ({ search, finndoffUser, onDelete }) => {
  const { error, response, del } = useApi("/api/me/brukerdefinertsok");
  const { enqueueSuccessMsg, enqueueErrorMsg } = useSnackbar();
  const { steder, kategorier } = useReferenceDataContext();

  const deleteSearch = async (search: Search) => {
    if (!window.confirm("Er du sikker?")) {
      return;
    }
    await del(`/${search.eksternId}`);
    if (response.ok) {
      onDelete(search);
      enqueueSuccessMsg("Søk slettet. Finndoffvarling deaktivert!");
    }
    if (error) {
      enqueueErrorMsg("Kunne ikke slette søk");
    }
  };

  const stedText =
    Array.from(search.stedIds)
      .map((stedId) => getNodeById(steder, stedId)?.label)
      .join(", ") || "Alle";

  const kategoriTekst = (() => {
    const kategorierLabels = Array.from(search.kategoriIds)
      .map((kategoriId) => getNodeById(kategorier, kategoriId).label);
  
    if (kategorierLabels.length === 0) {
      return "Alle";
    }
  
    const concatenatedText = kategorierLabels.join(", ");
    
    if (concatenatedText.length > 200) {
      return concatenatedText.substring(0, 197) + '...'; // Truncate and add three dots
    } else {
      return concatenatedText;
    }
  })();

  const mottakerTekst = search.mottakere.join(", ") || "Ingen";

  const { response: aboCreateSessionResponse, post: postAboCreateSession } = useApi(
    "/api/me/abonnement/create-session"
  );

  const createAbonnementCreateSession = async (stripePriceId: string) => {
    await postAboCreateSession({
      stripePriceId: stripePriceId,
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    }).then(() => {
      if (aboCreateSessionResponse.ok) window.location.href = aboCreateSessionResponse.data.url;
      else console.log(aboCreateSessionResponse);
    });
  };
  return (
    <Card sx={{ backgroundColor: "transparent" }}>
      <Link href={paths.searchEdit.replace(":id", search.eksternId)} sx={{ textDecoration: "none" }}>
        <Grid container>
          <Grid
            item
            xs={3}
            sx={{
              backgroundColor: "rgba(224, 240, 241)",
              backgroundPosition: "center top",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WorkOutlineIcon color="primary" fontSize="large" />
          </Grid>
          <Grid item xs={9} sx={{ backgroundColor: "white" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {search.navn}
              </Typography>
              {search.searchText && <Typography variant="body2">Fritekst: {search.searchText}</Typography>}
              {!search.searchText && <Typography variant="body2">&nbsp;</Typography>}
              <Typography variant="body2">Sted: {stedText}</Typography>
              <Typography variant="body2">Kategori: {kategoriTekst}</Typography>
              <Typography variant="body2">Mottakere: {mottakerTekst}</Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "flex-end",
                "& a": {
                  textDecoration: "none",
                },
              }}
            >
              {!finndoffUser?.active && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    createAbonnementCreateSession(config.stripePrice299);
                  }}
                >
                  Aktiver varsling - Årsabonnement KUN&nbsp;(299.-/mnd)
                </Button>
              )}

              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteSearch(search);
                }}
              >
                Slett
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Link>
    </Card>
  );
};
