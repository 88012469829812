import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, CircularProgress, Backdrop, Box, Button, Grid, Link, List, ListItem, ListItemText, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '../../../utils/hooks/useApi';
import { useUserContext } from "../../../utils/contexts/UserContext";
import { SupplierBulletin, UserInterest } from './datatypes';
import { useAuth0 } from "@auth0/auth0-react";

const SupplierBulletinDetail: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bulletinTenderId = queryParams.get('bulletinTenderId');  // Extract the bulletinTenderId from query parameters

    const [bulletin, setBulletin] = useState<SupplierBulletin | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [showCpvKoder, setShowCpvKoder] = useState<boolean>(false);
    const [selectedCpvKoder, setSelectedCpvKoder] = useState<number[]>([]);
    const { get: getBulletin, post: addUserInterest, del: removeUserInterest, error, loading: apiLoading } = useApi(`/api/bulletin/byDoffinAnbudId/${bulletinTenderId}`);
    const navigate = useNavigate();
    const { finndoffUser, isLoading } = useUserContext();  
    const { loginWithRedirect, isAuthenticated } = useAuth0(); // Use Auth0 for authentication

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            // User is not authenticated, redirect to login with a specific redirect_uri
            loginWithRedirect({
                authorizationParams: {
                    redirect_uri: `${window.location.origin}${window.location.pathname}`, // Static callback URL
                },
                appState: { returnTo: `${window.location.pathname}${window.location.search}` }, // Preserve the full path with query params
            });
        } else if (isAuthenticated) {
            // User is authenticated, fetch bulletin
            const fetchBulletin = async () => {
                const result = await getBulletin();
                if (result) {
                    setBulletin(result);
                }
                setLoading(false);
            };

            if (bulletinTenderId) {
                fetchBulletin().catch(console.error);
            }
        }
    }, [isLoading, isAuthenticated, bulletinTenderId, getBulletin, loginWithRedirect]);

    const handleAddInterest = async () => {
        const confirm = window.confirm("Firmaet som har opprettet bulletinen vil kunne se kontaktinfo slik at de kan kontakte dere. Ønsker du å fortsette?");
        if (confirm) {
            await addUserInterest(`/addUserInterest`, { CpvKodeIds: selectedCpvKoder });
            const result = await getBulletin();
            if (result) {
                setBulletin(result);
            }
        }
    };

    const handleRemoveInterest = async () => {
        await removeUserInterest(`/removeUserInterest`);
        const result = await getBulletin();
        if (result) {
            setBulletin(result);
        }
    };

    const handleCpvKodeChange = (id: number) => {
        setSelectedCpvKoder(prev => {
            if (prev.includes(id)) {
                return prev.filter(cpvId => cpvId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const isUserInterested = bulletin?.userInterests?.some(user => user.id === finndoffUser?.id);
    const isUserCreator = bulletin?.creator?.id === finndoffUser?.id;

    if (loading || isLoading) {
        return (
            <Backdrop open={true} style={{ zIndex: 1000, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
                <CircularProgress sx={{ color: 'white' }} />
                <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
                    Laster bulletin...
                </Typography>
            </Backdrop>
        );
    }

    if (!bulletin) {
        return <Typography variant="h6">Ingen bulletin funnet</Typography>;
    }

    return (
        <Container>
            {(loading || apiLoading) && (
                <Backdrop open={loading || apiLoading} style={{ zIndex: 1000, position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
                    <CircularProgress sx={{ color: 'white' }} />
                    <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
                        Laster bulletin...
                    </Typography>
                </Backdrop>
            )}
            {bulletin && (
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={8}>
                        <Box sx={{ mt: 4, pb: 4 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                <Link component="button" variant="body2" onClick={() => navigate(-1)} sx={{ cursor: 'pointer', marginRight: 2 }}>
                                    Tilbake til listen
                                </Link>
                            </Box>
                            <Typography variant="h4" gutterBottom>
                                {bulletin.title}
                            </Typography>
                            <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Interesserte underleverandører: </strong>{bulletin.userInterests?.length}</Typography>
                            <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Opprettet: </strong>{new Date(bulletin.createdAt).toLocaleDateString()}</Typography>
                            <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Oppdatert: </strong>{new Date(bulletin.updatedAt).toLocaleDateString()}</Typography>
                            <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Tilbudsfrist: </strong>{bulletin.limitDate ? new Date(bulletin.limitDate).toLocaleDateString() : 'N/A'}</Typography>
                            <Typography variant="body2" style={{ marginBottom: '10px' }}><strong>Beskrivelse: </strong>{bulletin.description}</Typography>
                            {!isUserCreator && ( // Hide interest button if user is the creator
                                isUserInterested ? (
                                    <Button variant="contained" color="secondary" onClick={handleRemoveInterest} sx={{ mt: 2, mb: 2 }}>
                                        Fjern interesse
                                    </Button>
                                ) : (
                                    <div>
                                        {!showCpvKoder ? (
                                            <Button variant="contained" color="primary" onClick={() => setShowCpvKoder(true)} sx={{ mt: 2, mb: 2 }}>
                                                Vis interesse
                                            </Button>
                                        ) : (
                                            <IconButton onClick={() => setShowCpvKoder(false)} sx={{ mt: 2, mb: 2 }}>
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                        {showCpvKoder && (
                                            <Box>
                                                <Typography variant="h6">Velg CPV-koder:</Typography>
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell padding="checkbox"></TableCell>
                                                                <TableCell>CPV Kode</TableCell>
                                                                <TableCell>Beskrivelse</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Array.from(new Set(bulletin.tender?.cpvKoder?.map(cpv => cpv.id))).map((cpvId) => {
                                                                const cpv = bulletin.tender?.cpvKoder?.find(cpvItem => cpvItem.id === cpvId);
                                                                if (!cpv) return null;
                                                                return (
                                                                    <TableRow key={cpv.id} hover onClick={() => handleCpvKodeChange(cpv.id)} sx={{ cursor: 'pointer', height: 40 }}>
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={selectedCpvKoder.includes(cpv.id)}
                                                                                onChange={() => handleCpvKodeChange(cpv.id)}
                                                                                name={cpv.kode}
                                                                                onClick={e => e.stopPropagation()}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>{cpv.kode}</TableCell>
                                                                        <TableCell>{cpv.beskrivelse}</TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Box sx={{ mt: 2 }}>
                                                    <Button variant="contained" color="primary" onClick={handleAddInterest}>
                                                        Submit Interest
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </div>
                                )
                            )}
                            {isUserCreator && (
                                <Box sx={{ mt: 4 }}>
                                    <Typography variant="h6">Interessert underleverandører:</Typography>
                                    <List>
                                        {bulletin.userInterests?.map((user: UserInterest) => (
                                            <ListItem key={user.id}>
                                                <ListItemText
                                                    primary={user.firmaNavn}
                                                    secondary={`Organisasjonsnummer: ${user.orgNummer} | E-post: ${user.epostadresse} | Telefonnummer: ${user.kontaktpersonMobil} | CPV-koder: ${user.cpvKoder.map(cpv => cpv.kode).join(', ')}`}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default SupplierBulletinDetail;
