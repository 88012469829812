import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SaveButtonAndModal } from "./SaveButtonAndModal";
import { canSaveSearch, useUserContext } from "../../../../utils/contexts/UserContext";
import { immutablySet } from "../../../../utils/tree";
import { Hierarchy } from "./Hierarchy";
import {
  isNew,
  Search,
  setSearchCheckedKategori,
  setSearchCheckedSted,
  setSearchFreeText,
  setSearchNoticeType,
} from "../../../../Types/Search";
import { useReferenceDataContext } from "../../../../utils/contexts/ReferenceDataContext";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getNodeById } from "../../../../utils/tree";
import { StyledChip } from "../../../../components/StyledChip";

interface SearchFormProps {
  currentSearch: Search;
  setCurrentSearch: (search: Search) => void;
  setActiveTendersOnly: (checked: boolean) => void;
  saveSearch: (search: Search) => Promise<Search>;
  setInitCurrentPage: (id: number) => void;
  sortField: string;          // Add sortField prop
  sortOrder: "asc" | "desc";  // Add sortOrder prop
}

export const SearchForm: React.FC<SearchFormProps> = ({
  currentSearch,
  setCurrentSearch,
  saveSearch,
  setActiveTendersOnly,
  setInitCurrentPage,
  sortField,        // Receive sortField prop
  sortOrder,        // Receive sortOrder prop
}) => {
  const { enqueueSuccessMsg } = useSnackbar();
  const { finndoffUser } = useUserContext();

  const { steder, kategorier } = useReferenceDataContext();
  const [openedKategorier, setOpenedKategorier] = useState<Set<number>>(new Set());
  const [openedSteder, setOpenedSteder] = useState<Set<number>>(new Set());
  const [searchText, setSearchText] = useState<string>("");
  const [checked, setChecked] = useState(false);

  // Determine if the checkbox should be disabled based on the sortField and sortOrder
  const isDefaultSort = sortField === "FileDate" && sortOrder === "desc";
  const isCheckedAndDisabled = !isDefaultSort;

  const setInputSearchText = (searchText: string) =>
    setCurrentSearch(
      setSearchFreeText(
        currentSearch,
        searchText,
        currentSearch.kategoriIds,
        currentSearch.stedIds,
        currentSearch.noticeType
      )
    );

  const setInputNoticeType = (type: string) => setCurrentSearch(setSearchNoticeType(currentSearch, type));

  const setInputActiveTendersOnly = (checked: boolean) => setActiveTendersOnly(checked);

  const setCheckedKategori = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedKategori(currentSearch, kategorier, id, checked));

  const setCheckedSted = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedSted(currentSearch, steder, id, checked));

  const setOpenedKategori = (id: number, opened: boolean) =>
    setOpenedKategorier(immutablySet(openedKategorier, id, opened));

  const setOpenedSted = (id: number, opened: boolean) => setOpenedSteder(immutablySet(openedSteder, id, opened));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.searchText = event.target.value;
    setSearchText(event.target.value);
    setInitCurrentPage(0);
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentSearch.visAktive = event.target.checked;
    setChecked(event.target.checked);
    setInputActiveTendersOnly(currentSearch.visAktive);
    setInitCurrentPage(0);
  };

  const doFreetextSearch = () => {
    if (searchText.length > 3) {
      enqueueSuccessMsg("Søker etter..." + currentSearch.searchText);
      setInputSearchText(currentSearch.searchText);
      setInitCurrentPage(0);
    }
  };

  const doNoticeTypeSearch = (event: SelectChangeEvent<{ value: unknown }>) => {
    currentSearch.noticeType = event.target.value as string;
    setInputNoticeType(currentSearch.noticeType);
    setInitCurrentPage(0);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (searchText.length > 3) {
        enqueueSuccessMsg("Søker etter..." + currentSearch.searchText);
        setInputSearchText(currentSearch.searchText);
        setInitCurrentPage(0);
      }
    }
  };

  const tooltip = "Søket støtter også operatorene: * (wildcard)  + (OG) | (ELLER)";

  function stedText() {
    if (currentSearch.stedIds.size < 1) return "Hele Norge";
    else if (currentSearch.stedIds.size === 1)
      return Array.from(currentSearch.stedIds).map((stedId) => getNodeById(steder, stedId)?.label);
    else return "flere steder";
  }

  function kategoriShort() {
    if (currentSearch.kategoriIds.size < 1) return " alle CPV-nummer ";
    else if (currentSearch.kategoriIds.size === 1) return " ett CPV-nummer ";
    else return " flere CPV-nummer ";
  }

  function ProcessExistingSearchName(currentSearchText: string, newSearchName: string) {
    if (
      currentSearchText.includes("Alle anbud") ||
      currentSearchText.includes("CPV-nummer") ||
      currentSearchText.includes("flere steder") ||
      currentSearchText.includes("Hele Norge")
    )
      return newSearchName;
    else return currentSearchText;
  }

  function setValue() {
    var fritekstName = currentSearch.searchText ? currentSearch.searchText : "Alle anbud";
    fritekstName = fritekstName.length > 100 ? "Alle anbud" : fritekstName; // to limit the autognerated search names

    var searchNameCategory = kategoriShort();
    var searchNameLocation = stedText();

    fritekstName = fritekstName + searchNameCategory + searchNameLocation;

    currentSearch.navn = isNew(currentSearch)
      ? fritekstName
      : ProcessExistingSearchName(currentSearch.navn, fritekstName);

    return currentSearch.navn;
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          color: "#484848",
          margin: "1rem",
        }}
      >
        <Grid container direction="column" spacing={2}>
          {!isNew(currentSearch) && (
            <>
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Ditt søk: {currentSearch.navn}
                </Typography>
              </Grid>
            </>
          )}
          {canSaveSearch(finndoffUser) && (
            <Grid item>
              <SaveButtonAndModal
                saveSearch={saveSearch}
                currentSearch={currentSearch}
                initialSearchName={setValue()}
              />
            </Grid>
          )}
          <Grid item>
            <Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  <Typography variant="h5">Nøkkelord i kunngjøringer</Typography>
                </Box>

                <Box
                  sx={{
                    borderStyle: "solid",
                    border: "0px",
                    borderColor: "gray",
                    borderRadius: "2px",
                    display: "flex",
                  }}
                >
                  <Tooltip title={tooltip}>
                    <TextField
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      placeholder="Søk"
                      inputProps={{ "aria-label": "fritekst" }}
                      value={currentSearch.searchText}
                      color="primary"
                      variant="standard"
                      size="small"
                      sx={{
                        backgroundColor: "white",
                        justifyContent: "center",
                        alignItems: "left",
                        width: "100%",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={tooltip}>
                    <Button
                      aria-label="search"
                      onClick={doFreetextSearch}
                      endIcon={<SearchIcon />}
                      sx={{
                        height: "inherit",
                        borderBottomRightRadius: "4px",
                        borderTopRightRadius: "4px",
                        float: "right",
                      }}
                      size="large"
                    />
                  </Tooltip>
                </Box>
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckedAndDisabled || checked}  // Checked if non-default sorting
                        onChange={handleActiveChange}
                        color={"primary"}
                        disabled={isCheckedAndDisabled}  // Disabled if non-default sorting
                      />
                    }
                    label="Vis kun aktive"
                  />
                </FormGroup>
              </Box>
              <Box marginTop={"1rem"}>
                <FormGroup>
                  <FormControl fullWidth>
                    <Typography variant="h5">Kunngjøringstype</Typography>
                    <Select
                      labelId="kunngjoringstyper"
                      id="kunngjoringstyper-select"
                      value={currentSearch.noticeType as any}
                      label="Kunngjøringstyper"
                      onChange={doNoticeTypeSearch}
                    >
                      <MenuItem value={"All"}>Alle</MenuItem>
                      <MenuItem value={"Pin"}>Veiledende</MenuItem>
                      <MenuItem value={"Notice"}>Kunngjøring av konkurranse</MenuItem>
                      <MenuItem value={"Award"}>Tildeling</MenuItem>
                      <MenuItem value={"Intention"}>Intensjon</MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5">Sted</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Hierarchy
                    tree={steder}
                    nodes={steder.rootNodes[0].children}
                    openedNodeIds={openedSteder}
                    checkedNodeIds={currentSearch.stedIds}
                    setChecked={setCheckedSted}
                    setOpened={setOpenedSted}
                  />
                </AccordionDetails>
              </Accordion>
              {Array.from(currentSearch.stedIds).map((stedId) => {
                return <StyledChip key={"sted_chip_" + stedId} label={steder.nodeMap[stedId]?.label} />;
              })}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h5">CPV-nummer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Hierarchy
                    tree={kategorier}
                    nodes={kategorier.rootNodes}
                    openedNodeIds={openedKategorier}
                    checkedNodeIds={currentSearch.kategoriIds}
                    setChecked={setCheckedKategori}
                    setOpened={setOpenedKategori}
                  />
                </AccordionDetails>
              </Accordion>
              {Array.from(currentSearch.kategoriIds).map((kategoriId) => {
                return (
                  <StyledChip
                    key={"kategori_chip_" + kategoriId}
                    label={kategorier.nodeMap[kategoriId].label.split(" ")[0]}
                  />
                );
              })}
            </Box>
          </Grid>
          {!isNew(currentSearch) && currentSearch.mottakere.length > 0 && (
            <Grid item>
              <Typography variant="subtitle1">Mottakere:</Typography>
              <Grid container spacing={1}>
                {currentSearch.mottakere.map((x, i) => {
                  return (
                    <Grid item key={x}>
                      <StyledChip key={i} label={x} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};
