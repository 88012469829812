import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { FinndoffUser } from "../../utils/contexts/UserContext";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { useApi } from "../../utils/hooks/useApi";
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import React from "react";
import { forEach } from "lodash";
import { PrimaryButton, SearchSaveButton } from "../../components/Buttons/PrimaryButton";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { config } from "../../config";

interface SignupFormProps {
  searchesFormTrigger: (setSearchesForm: boolean) => void;
  inviteError: (hasInviteError: boolean) => void;
  externalUserId: string;
  industryCode: string;
}

export const SearchForm: FunctionComponent<SignupFormProps> = ({ searchesFormTrigger, inviteError, externalUserId, industryCode}) => {
  const { enqueueErrorMsg } = useSnackbar();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { getAllIndustries: apiAllIndustriesMethod } = GetIndustriesMethods();
  const [preselectedIndustryName, setPreselectedIndustryName] = useState("");
  const [isSearchesLoading, setIsSearchesLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const frontendUrl = window.location.origin;
  
  const getIndustries = async () => {
    try {
      const results = await apiAllIndustriesMethod();
      setItems(results);
    } catch (e) {
      enqueueErrorMsg(`Error fetching industries: ${e}`);
    }
  };
  
  const setPreselectedIndustry = async (industryCode:string) => {
    try {
      const preselectedItem = items.find(item => industryCode.startsWith(item.code)); //industryCode.startsWith(item.Code)
      if (preselectedItem) {
        const preselectedIndustryText = (
          <Typography variant="body1">
            Varslinger for <strong>{preselectedItem.shortName}</strong> er allerede valgt.
          </Typography>
        );

        setPreselectedIndustryName(preselectedIndustryName);
  
        // Do something with the preselected item, such as updating the selectedItems state
        setSelectedItems([preselectedItem.id]);
      } else {
        // Handle case when no matching item is found
        console.log(`No item found with code starting with '${industryCode}'.`);
      }
    } catch (e) {
      console.log(`Error finding with code starting with '${industryCode}'.`);
    }
  };

  const findIndustryCodesForSelectedIds = () => {
    const industryCodes = selectedItems.map((id) => {
      const selectedItem = items.find((item) => item.id === id);
      return selectedItem ? selectedItem.code : null;
    });
  
    // Filter out null values
    return industryCodes.filter((code) => code !== null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getIndustries();
      } catch (error) {
        console.error('Error fetching industries:', error);
        enqueueErrorMsg(`Error fetching industries: ${error}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      setPreselectedIndustry(industryCode);
    }
  }, [items]);

  const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterText(value);
    // Preserve selected items when filtering
    if (!value) {
      setSelectedItems(selectedItems);
    }
  };

  const handleClearFilter = () => {
    setSelectedItems(selectedItems);
    setFilterText("");
  };

  const columns: GridColDef[] = [
    { field: 'shortName', headerName: 'Bransjenavn', width: 250 },
    // { field: 'id', headerName: 'Id', width: 130 },
  ];

  const filteredItems = items
  .filter((item) => {
    const nameMatches = item.name.toLowerCase().includes(filterText?.toLowerCase());
    const notesMatches = item.notes.toLowerCase().includes(filterText?.toLowerCase());
    const subIndustryMatches = item?.subIndustriesDescription?.toLowerCase().includes(filterText?.toLowerCase());
    // Add more criteria if needed
    return nameMatches || notesMatches || subIndustryMatches;
  })
  .sort((a, b) => {
    // Sorting logic remains the same
    const aIsSelected = selectedItems.includes(a);
    const bIsSelected = selectedItems.includes(b);
    
    if (aIsSelected && !bIsSelected) {
      return -1;
    } else if (!aIsSelected && bIsSelected) {
      return 1;
    } else {
      return 0;
    }
  });

  const handleCellClick = (params: { id: any; }) => {
    const clickedItemId = params.id; // Assuming 'id' is the unique identifier of each item
    const isItemSelected = selectedItems.includes(clickedItemId);
  
    // Toggle selection
    if (isItemSelected) {
      // Remove item from selectedItems
      setSelectedItems(selectedItems.filter((id) => id !== clickedItemId));
    } else {
      // Add item to selectedItems
      setSelectedItems([...selectedItems, clickedItemId]);
    }
  };

  const findItemById = (id: number) => {
    return items.find((item) => item.id === id);
  };
  
  const handleRemoveSelectedItem = (itemId: any) => {
    setSelectedItems(selectedItems.filter((item) => item !== itemId));
  };

  const toUserIndustrySearchesApiBody = (): UserSearches => ({
    ExternalUserId: externalUserId,
    Industries: findIndustryCodesForSelectedIds(),
  });

  const toUserPaymentUpdateApiBody = (): PaymentRedirect => ({
    ExternalUserId: externalUserId,
    SuccessUrl : `${frontendUrl}/signupresult/`,
    CancelUrl : `${frontendUrl}/signupresult/`
  });

  const { userIndustrySearchesMethod: apiIndustryUserSearches } = IndustrySearchesApiMethods(toUserIndustrySearchesApiBody());
  const { userPaymentUpdateMethod: apiUserPaymentUpdate } = UpdatePaymentsApiMethods(toUserPaymentUpdateApiBody());

  const saveSearches = async () => {
    try {
      setIsSearchesLoading(true);
  
      await apiIndustryUserSearches().then(async (results) => {
        if (results?.externalUserId) {
          setUserId(results?.id);
          await setSearchResultsAction(results); // Await the second API call here
          setIsSearchesLoading(false);
        } else if (results?.externalUserId == null) {
          enqueueErrorMsg("Feil på lagring av bransjesøk");
          inviteError(true);
        }
      });
      // setTimeout(() => history.push(paths.portal), 300);
    } catch (e) {
      setIsSearchesLoading(false);
      enqueueErrorMsg(`Kunne ikke lagre bransjesøk: ${e.toString()}`);
    }
  };
  
  const setSearchResultsAction = async (user: FinndoffUser) => {
    console.log(user?.paymentMethod);
    if (user?.paymentMethod === "Card") {
      try {
        // In case of card
        const results = await apiUserPaymentUpdate();
        if (results?.url) {
          // Redirect to the URL
          window.location.href = results.url;
        } else if (results?.url == null) {
          enqueueErrorMsg("Feil på lagring av bransjesøk");
          inviteError(true);
        }
      } catch (error) {
        // Handle potential errors from the API call
        enqueueErrorMsg("An error occurred while updating user payment");
        console.error(error);
      }
    } else {
      // In case of invoice
      searchesFormTrigger(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ backgroundColor: '#E5F3F3', marginTop: '0.5%' }}>
        <Grid item xs={4} md={4} style={{ marginLeft: '10%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Valgte bransjer</Typography>
            </Grid>
            <Grid item>
              <div>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {selectedItems.map((item) => (
                    <li key={item} style={{ display: 'inline-block', margin: '4px' }}>
                      <div style={{ backgroundColor: '#FFFFFF', borderRadius: '15px', padding: '4px 8px', display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '4px' }}>{findItemById(item).shortName}</span>
                        <IconButton onClick={() => handleRemoveSelectedItem(item)}>
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} md={5} style={{ marginLeft: '1%', marginBottom: '0.5%', paddingRight:'1%'}}>
          <Grid item xs={12}>
            <Typography variant="h6">Bransjer</Typography>
          </Grid>
          {/* Sign up form fields */}
          <Grid item style={{ backgroundColor: 'white', borderRadius: '8px', padding: '2%', flexGrow: 1 }}>
            {/* Render both signup form and loading indicator conditionally */}
            <div style={{ overflowX: "auto" }}></div>
            <Typography style={{ paddingLeft: '10px', paddingBottom: '10px'}}>
              Velg bransjer du ønsker varslinger for. 
              {preselectedIndustryName}
            </Typography>
            <TextField
              type="text"
              placeholder="Søk etter bransje..."
              value={filterText}
              onChange={handleFilterTextChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterText && (
                      <IconButton onClick={handleClearFilter} edge="end" tabIndex={-1}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ width: '100%', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px',  }}
            />
            <Grid item style={{ paddingBottom: '10px', paddingLeft: '10px' }}>
              <DataGrid
                rows={filteredItems}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                // onRowSelectionModelChange={handleSelectionChange}
                onCellClick={handleCellClick}
                rowSelectionModel={selectedItems.map((item) => item)} // Preselect items
                hideFooterSelectedRowCount
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="end"
            sx={{
              "& > *": {
                margin: (theme) => theme.spacing(1),
              },
            }}
          >
            <PrimaryButton onClick={saveSearches} text={"Registrer bransjesøk!"} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export type PaymentSessionResponse = {
  url: string;
};

export type UserSearches = {
  ExternalUserId: string;
  Industries : string[];
};

export type Industry = {
  Id: string;
  Code : string;
  ParentCode : string;
  Level : string;
  Name : string;
  ShortName : string;
  Notes : string;
  SubIndustriesDescription : string;
  // CpvKodeDto[] : CpvKodeDto[] 
};

export type PaymentRedirect = {
  ExternalUserId: string;
  SuccessUrl : string;
  CancelUrl : string;
};

export const GetIndustriesMethods = () => {
  const { error, loading, get } = useApi("/api/industry");
  return {
    error,
    loading,
    getAllIndustries: async (): Promise<Industry[]> => {
      const apiResponse = await get("");
      return apiResponse;
    },
  };
};

export const IndustrySearchesApiMethods = (industries: UserSearches) => {
  const { error, loading, post } = useApi("/api/me/bruker/searchessignup");
  return {
    error,
    loading,
    userIndustrySearchesMethod: async (): Promise<FinndoffUser> => {
      const body = industries;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};

export const UpdatePaymentsApiMethods = (paymentUser: PaymentRedirect) => {
  const { error, loading, post } = useApi("/api/me/bruker/cardsignup");
  return {
    error,
    loading,
    userPaymentUpdateMethod: async (): Promise<PaymentSessionResponse> => {
      const body = paymentUser;
      const apiResponse = await post("", body);
      return apiResponse;
    },
  };
};
