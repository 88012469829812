import React from "react";
import { Grid, Box, Link } from "@mui/material";
import { Logo } from "../../../components/Logo/Logo";
import { useAuth0 } from "@auth0/auth0-react";

import { paths } from "../../../app/paths";
import { ToolBarMenu } from "./ToolBarMenu";
import { LinkType } from "../Portal";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { LightButton } from "../../../components/Buttons/LightButton";
import { useUserContext } from "../../../utils/contexts/UserContext";

export const TopToolBar: React.FunctionComponent = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { finndoffUser, hasProcurementPlanner } = useUserContext();

  // handle GA Register button click and Signup
  const handleLoginWithRedirect = () => {
    loginWithRedirect({ authorizationParams: { screen_hint: "signup", display: "page", ui_locales: "nb" } });
  };

  const links: Array<LinkType> = [
    { text: "Om oss", path: paths.about, onlyWhenAuthenticated: false },
    { text: "Nyheter", path: paths.news, onlyWhenAuthenticated: false },
    { text: "FAQ", path: paths.faq, onlyWhenAuthenticated: false },
    { text: "Kontakt", path: paths.contact, onlyWhenAuthenticated: false },
    { text: "Priser", path: paths.prices, onlyWhenAuthenticated: false },
    { text: "Søk", path: paths.search, onlyWhenAuthenticated: true },
    { text: "Mine søk", path: paths.searches, onlyWhenAuthenticated: true },
  ];

  const premiumlinks: Array<LinkType> = [
    { text: "Investeringsplaner", path: paths.procurementplanner, onlyWhenAuthenticated: true },
    { text: "Følg selskaper", path: paths.companies, onlyWhenAuthenticated: true },
    // { text: "Underleverandører", path: paths.supplierbulletinlist, onlyWhenAuthenticated: true },
  ];


  return (
    <>
      <Box marginLeft="1rem" marginRight="1rem">
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={10} sm={4} md={9} lg={10} xl={9}>
            <Grid container direction="row" alignItems="center" spacing={1} justifyContent="flex-start">
              <Grid item>
                <Link href={"/portal"} target="_parent" style={{ textDecoration: "none" }}>
                  <Logo />
                </Link>
              </Grid>

              {links
                .filter((link) => !link.onlyWhenAuthenticated)
                .map((link, index) => {
                  return (
                    <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                      <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                    </Grid>
                  );
                })}
              {isAuthenticated &&
                links
                  .filter((link) => link.onlyWhenAuthenticated)
                  .map((link, index) => {
                    return (
                      <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                        <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                      </Grid>
                    );
                  })}
                {isAuthenticated && hasProcurementPlanner &&
                premiumlinks
                  .filter((link) => link.onlyWhenAuthenticated)
                  .map((link, index) => {
                    return (
                      <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                        <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>

          <Grid item xs={2} sm={8} md={3} lg={2} xl={3}>
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
              spacing={1}
              justifyContent="flex-end"
            >
              {!isAuthenticated ? (
                <>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <PrimaryButton link={paths.signup} text={"Prøv oss"} />
                  </Grid>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton onClick={handleLoginWithRedirect} text={"Logg inn"} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton text={"Min profil"} link={`/portal/${paths.profile}`} variant="text" />
                  </Grid>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton link={paths.logout} text={"Logg ut"} />
                  </Grid>
                </>
              )}

              <Grid item sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
                <ToolBarMenu
                  links={links}
                  linksPremium={premiumlinks}
                  handleLoginWithRedirect={handleLoginWithRedirect}
                  isAuthenticated={isAuthenticated}
                  isPremium={hasProcurementPlanner}
                ></ToolBarMenu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

