import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { AnbudSummary, PagedList } from "./datatypes";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { SearchResult } from "./SearchResult";

interface SearchResultsProps {
  isLoading: boolean;
  maybeSearchResponse: PagedList<AnbudSummary> | undefined;
  checkedStedIds: Set<number>;
  checkedKategoriIds: Set<number>;
  checkedKategoriCPV: Set<number>;
  setCheckedKategori: (id: number, checked: boolean) => void;
  setCheckedSted: (id: number, checked: boolean) => void;
  showActiveTendersOnly: boolean;
}

const isActiveTender = (anbud: AnbudSummary): boolean => {
  let contractDate = new Date(anbud.limitDate);
  let erAktivKunngjøring: boolean;

  if (anbud.limitDate === null) return true;

  if (anbud.noticeType === "Award") return true;

  if (contractDate.getTime() - Date.now() >= 0) {
    erAktivKunngjøring = true;
  } else {
    erAktivKunngjøring = false;
  }
  return erAktivKunngjøring;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  isLoading,
  maybeSearchResponse,
  checkedStedIds,
  checkedKategoriIds,
  checkedKategoriCPV,
  setCheckedKategori,
  setCheckedSted,
  showActiveTendersOnly,
}) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!maybeSearchResponse) {
    return null;
  }
  const searchResponse = maybeSearchResponse as PagedList<AnbudSummary>;

  function getKunngjøringerTekst() {
    if (searchResponse.totalCount === 0) {
      return "Ingen kunngjøringer matcher søket ditt";
    } else if (searchResponse.totalCount === 1) {
      return "1 kunngjøring matcher søket ditt";
    } else {
      return `${searchResponse.totalCount} kunngjøringer matcher søket ditt`;
    }
  }

  return (
    <Paper style={{ padding: "16px" }} elevation={0}>
      {showActiveTendersOnly ? (
        <div>
          <Typography variant="h5" gutterBottom color="primary" align="center">
            Vi fant disse aktive kunngjøringene som matcher søket ditt
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h5" gutterBottom color="primary" align="center">
            {getKunngjøringerTekst()}
          </Typography>
        </div>
      )}

      {searchResponse.items.map((res, i) => (
        <div key={i}>
          {isActiveTender(res) && (
            <Box
              sx={{
                padding: 1,
                backgroundColor: "white",
              }}
            >
              <Divider />{" "}
              <SearchResult
                summary={res}
                checkedStedIds={checkedStedIds}
                checkedKategoriIds={checkedKategoriIds}
                checkedKategoriCPV={checkedKategoriCPV}
                setCheckedKategori={setCheckedKategori}
                setCheckedSted={setCheckedSted}
              />
            </Box>
          )}
          {!isActiveTender(res) && !showActiveTendersOnly && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.grey[200],
                opacity: "75%",
                paddingLeft: 1,
                paddingBottom: 1,
                paddingRight: 1,
                borderRadius: "4px",
                marginTop: 1,
              }}
            >
              {" "}
              <Typography variant="h6" gutterBottom align="left">
                Utløpt
              </Typography>
              <SearchResult
                summary={res}
                checkedStedIds={checkedStedIds}
                checkedKategoriIds={checkedKategoriIds}
                checkedKategoriCPV={checkedKategoriCPV}
                setCheckedKategori={setCheckedKategori}
                setCheckedSted={setCheckedSted}
              />
            </Box>
          )}
        </div>
      ))}
    </Paper>
  );
};
