import React, { useEffect, useState, useCallback } from 'react';
import { Grid, TextField, List, ListItem, Typography, Link, Box, InputAdornment, IconButton, Button, CircularProgress, Backdrop, MenuItem } from '@mui/material';
import { ProcurementPlan } from './datatypes';
import LaunchIcon from '@mui/icons-material/Launch';
import { PlannedProcurementList } from './PlannedProcurementsList';
import dayjs from 'dayjs';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useApi } from '../../../utils/hooks/useApi';
import { isAuthenticated, useUserContext } from '../../../utils/contexts/UserContext';
import { useNavigate } from 'react-router-dom';

interface ProcurementPlannerProps {}

export const ProcurementPlanner: React.FC<ProcurementPlannerProps> = () => {
  const [selectedPlan, setSelectedPlan] = useState<ProcurementPlan | null>(null);
  const [procurementPlans, setData] = useState<ProcurementPlan[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<ProcurementPlan[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedPlaceNumber, setSelectedPlaceNumber] = useState<string>('');
  const [reportContentFilter, setReportContentFilter] = useState<string>('');
  const [plannedProcurementFilter, setPlannedProcurementFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const { finndoffUser, hasCompanyFollower } = useUserContext();
  const navigate = useNavigate();

  const { get: getAllProcurementPlans, error: allPlansError, loading: allPlansLoading } = useApi(
    `/api/procurement/all?filterText=${filterText}&selectedPlaceNumber=${selectedPlaceNumber}&reportContentFilter=${reportContentFilter}&plannedProcurementFilter=${plannedProcurementFilter}&currentPage=${currentPage}&rowsPerPage=${rowsPerPage}`
  );

  const { get: getProcurementPlan, error: planError, loading: planLoading } = useApi(
    `/api/procurement`
  );

  const handleFilterChange = (text: string, placeNumber: string, reportContent: string, procurementText: string) => {
    setFilterText(text);
    setSelectedPlaceNumber(placeNumber);
    setReportContentFilter(reportContent);
    setPlannedProcurementFilter(procurementText);
  };

  const handlePlanSelect = async (planId: number) => {
    setLoading(true);
    const result = await getProcurementPlan(`/${planId}`);
    setSelectedPlan(result);
    setLoading(false);
  };

  const fetchPlans = async () => {
    setLoading(true);
    const results = await getAllProcurementPlans();
    setData(results || []);
    setFilteredPlans(results || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchPlans().catch(console.error);
  }, [currentPage]);

  useEffect(() => {
    fetchPlans().catch(console.error);
  }, [selectedPlaceNumber]);

  useEffect(() => {
    if (isAuthenticated && finndoffUser && finndoffUser?.selectedProduct?.productName !== 'PREMIUM') {
      navigate('/');
    }
  }, [isAuthenticated, hasCompanyFollower, finndoffUser, navigate]);

  const setReportElement = (selectedPlan: ProcurementPlan): React.ReactNode => {
    if (selectedPlan.reportUrl) {
      return (
        <Typography>
          <Link href={selectedPlan.reportUrl} target="_blank" rel="noopener noreferrer">
            Link til rapport
            <LaunchIcon fontSize="inherit" style={{ verticalAlign: "middle" }} />
          </Link>
        </Typography>
      );
    } else {
      return null;
    }
  };

  const setPlannedProcurementElement = (selectedPlan: ProcurementPlan): React.ReactNode => {
    console.log('Selected Plan:', selectedPlan);
    if (selectedPlan.plannedProcurements && selectedPlan.plannedProcurements.length > 0) {
      return (
        <Grid item sx={{ paddingRight: '50px' }}>
          <Box display="inline" sx={{ fontWeight: "bold" }}>
            Planlagte innkjøp (Maskinlest)
          </Box>
          <List>
            <PlannedProcurementList selectedPlan={selectedPlan} />
          </List>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const handleClearNameFilter = () => {
    setFilterText('');
  };

  const handleClearContentFilter = () => {
    setReportContentFilter('');
  };

  const handleSearch = useCallback(() => {
    setCurrentPage(1);
    fetchPlans().catch(console.error);
  }, [filterText, selectedPlaceNumber, reportContentFilter, plannedProcurementFilter, currentPage, rowsPerPage]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSearch]);

  return (
    <div style={{ position: 'relative' }}>
      {(loading || allPlansLoading || planLoading) && (
        <Backdrop open={loading || allPlansLoading || planLoading} style={{ zIndex: 1000, position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
          <CircularProgress sx={{ color: 'white' }} />
          <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
            Laster innhold...
          </Typography>
        </Backdrop>
      )}
      <Grid container spacing={1} sx={{ paddingLeft: '16px' }} direction={{ xs: 'column', sm: 'row' }}>
        <Grid item xs={12} sm={2}>
          <Grid sx={{ paddingBottom: '15px' }} item>
            <Typography variant="h6">Organisasjoner</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Filtrer organisasjoner"
              variant="outlined"
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              fullWidth
              value={filterText}
              onChange={(e) => handleFilterChange(e.target.value, selectedPlaceNumber, reportContentFilter, plannedProcurementFilter)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterText && (
                      <IconButton onClick={handleClearNameFilter} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || allPlansLoading || planLoading,
              }}
            />
            <TextField
              select
              label="Fylker"
              value={selectedPlaceNumber}
              onChange={(e) => handleFilterChange(filterText, e.target.value, reportContentFilter, plannedProcurementFilter)}
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              variant="outlined"
              fullWidth
              disabled={loading || allPlansLoading || planLoading}
            >
              <MenuItem value="">Alle</MenuItem>
              <MenuItem value="3">Oslo</MenuItem>
              <MenuItem value="11">Rogaland</MenuItem>
              <MenuItem value="15">Møre og Romsdal</MenuItem>
              <MenuItem value="18">Nordland</MenuItem>
              <MenuItem value="31">Østfold</MenuItem>
              <MenuItem value="32">Akershus</MenuItem>
              <MenuItem value="33">Buskerud</MenuItem>
              <MenuItem value="34">Innlandet</MenuItem>
              <MenuItem value="39">Vestfold</MenuItem>
              <MenuItem value="40">Telemark</MenuItem>
              <MenuItem value="42">Agder</MenuItem>
              <MenuItem value="46">Vestland</MenuItem>
              <MenuItem value="50">Trøndelag</MenuItem>
              <MenuItem value="55">Troms</MenuItem>
              <MenuItem value="56">Finnmark</MenuItem>
              <MenuItem value="21">Svalbard</MenuItem>
              <MenuItem value="22">Jan Mayen</MenuItem>
            </TextField>
            <TextField
              label="Søk i rapportinnhold"
              variant="outlined"
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              fullWidth
              value={reportContentFilter}
              onChange={(e) => handleFilterChange(filterText, selectedPlaceNumber, e.target.value, plannedProcurementFilter)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {reportContentFilter && (
                      <IconButton onClick={handleClearContentFilter} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || allPlansLoading || planLoading,
              }}
            />
            <TextField
              label="Søk i planlagte innkjøp"
              variant="outlined"
              sx={{ marginBottom: '1rem', paddingRight: '10px' }}
              fullWidth
              value={plannedProcurementFilter}
              onChange={(e) => handleFilterChange(filterText, selectedPlaceNumber, reportContentFilter, e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {plannedProcurementFilter && (
                      <IconButton onClick={() => setPlannedProcurementFilter('')} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                disabled: loading || allPlansLoading || planLoading,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              fullWidth
              disabled={loading || allPlansLoading || planLoading}
              sx={{ marginBottom: '1rem' }}
            >
              Søk
            </Button>
            <List>
              {filteredPlans.map((plan) => (
                <ListItem 
                  button 
                  key={plan.id} 
                  onClick={() => handlePlanSelect(plan.id)}
                  selected={selectedPlan?.id === plan.id}
                  sx={{ 
                    backgroundColor: selectedPlan?.id === plan.id ? 'rgba(0, 0, 0, 0.02)' : 'transparent'
                  }}
                >
                  <Typography>{plan.organizationName}</Typography>
                </ListItem>
              ))}
            </List>
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={handlePrevPage}
                disabled={loading || allPlansLoading || planLoading || currentPage === 1}
              >
                Forrige
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={loading || allPlansLoading || planLoading || filteredPlans.length < rowsPerPage}
              >
                Neste
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={0.3}>
        </Grid>
        <Grid item xs={12} sm={9}>
          {selectedPlan ? (
            <div>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">
                    {selectedPlan.organizationName}
                    {selectedPlan.reportYear ? ` (${dayjs(selectedPlan.reportYear).format('YYYY')})` : ''}
                  </Typography>
                  {setReportElement(selectedPlan)}
                </Grid>
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="grey.300"
                  padding="10px"
                  borderRadius="4px"
                  marginTop="20px"
                  width="100%"
                >
                  <InfoOutlinedIcon sx={{ marginRight: '10px' }} />
                  <Typography variant="body1">
                    Maskinlagde oppsummeringer kan være feil. Sjekk rapporten for fullstendig informasjon.
                  </Typography>
                </Box>
                <Grid item>
                  <Box sx={{ fontWeight: "bold" }} display="inline">
                    Beskrivelse
                  </Box>
                  <Typography>{selectedPlan.description}</Typography>
                </Grid>
                {setPlannedProcurementElement(selectedPlan)}
              </Grid>
            </div>
          ) : (
            !loading && !allPlansLoading && !planLoading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="30%"
                paddingBottom="30%"
                paddingTop="10%"
              >
                <Typography variant="h6">Velg en organisasjon</Typography>
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProcurementPlanner;
