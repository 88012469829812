import React from "react";
import { Node, nodeIdOrParentChecked, someChildrenChecked, Tree } from "../../../../utils/tree";
import CollapseIcon from "@mui/icons-material/IndeterminateCheckBox";
import ExpandIcon from "@mui/icons-material/AddBox";
import { Box, Checkbox } from "@mui/material";
import css from "./hierarchy.module.scss";

interface HierarchyProps {
  tree: Tree;
  nodes: Node[];
  openedNodeIds: Set<number>;
  checkedNodeIds: Set<number>;
  setChecked: (id: number, checked: boolean) => void;
  setOpened: (id: number, opened: boolean) => void;
}

const HierarchyPure: React.FC<HierarchyProps> = ({
  tree,
  nodes,
  openedNodeIds,
  checkedNodeIds,
  setChecked,
  setOpened,
}) => {
  return (
    <ul className={css.hierarchy}>
      {nodes.map((node) => {
        const { id, label, children } = node;

        const opened = openedNodeIds.has(id);
        const checked = nodeIdOrParentChecked(tree, checkedNodeIds, id);

        return (
          <React.Fragment key={id}>
            <li>
              {children.length > 0 ? (
                <span onClick={() => setOpened(id, !opened)}>{opened ? <CollapseIcon /> : <ExpandIcon />}</span>
              ) : (
                <Box width="20px" minHeight="1px" sx={{ float: "left" }} />
              )}
              <Checkbox
                color="primary"
                checked={checked}
                indeterminate={!checked && someChildrenChecked(node, checkedNodeIds)}
                onChange={() => setChecked(id, !checked)}
              />
              <span
                onClick={() => setOpened(id, !opened)}
                className={node.children.length > 0 ? css.expandable : undefined}
              >
                {label}
              </span>
            </li>
            {opened && (
              <Hierarchy
                tree={tree}
                nodes={node.children}
                openedNodeIds={openedNodeIds}
                checkedNodeIds={checkedNodeIds}
                setChecked={setChecked}
                setOpened={setOpened}
              />
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export const Hierarchy: React.FC<HierarchyProps> = HierarchyPure;
