import React from "react";
import { List, ListItem, Typography, Divider, Grid, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs"; // Import dayjs library for date formatting
import { ProcurementPlan } from "./datatypes";

interface PlannedProcurementListProps {
    selectedPlan: ProcurementPlan;
}

export const PlannedProcurementList: React.FC<PlannedProcurementListProps> = ({
    selectedPlan
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <List>
            {/* Header */}
            <ListItem>
                <ListItemText>
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 3 : 2}>
                            <Typography sx={{ fontWeight: "bold", wordBreak: "break-word", hyphens: "auto", fontSize: isMobile ? "0.8rem" : "1rem" }}>
                                Tittel
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 7 : 8}>
                            <Typography sx={{ fontWeight: "bold", fontSize: isMobile ? "0.8rem" : "1rem" }}>Beskrivelse</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{ fontWeight: "bold", fontSize: isMobile ? "0.8rem" : "1rem" }}>Fullførelsesår</Typography>
                        </Grid>
                    </Grid>
                </ListItemText>
            </ListItem>

            {/* Horizontal Divider */}
            <Divider />

            {/* Data Rows */}
            {selectedPlan.plannedProcurements.map((plannedProcurement, index) => (
                <div key={index}>
                    {/* Data Row */}
                    <ListItem>
                        <ListItemText>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 3 : 2}>
                                    <Typography sx={{ wordBreak: "break-word", hyphens: "auto", fontSize: isMobile ? "0.8rem" : "1rem" }}>
                                        {plannedProcurement.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={isMobile ? 7 : 8}>
                                    <Typography sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}>{plannedProcurement.description}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}>
                                        {dayjs(plannedProcurement.finishingYear).isValid()
                                            ? dayjs(plannedProcurement.finishingYear).format('YYYY')
                                            : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListItem>

                    {/* Horizontal Divider */}
                    <Divider />
                </div>
            ))}
        </List>
    );
};
