import { FunctionComponent, useEffect, useState } from "react";
import { Box, Grid, Paper, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'; // Importing the Close Icon
import { useApi } from "../../../utils/hooks/useApi";
import { AnbudSummary, PagedList } from "./datatypes";
import { SearchResults } from "./SearchResults";
import { SearchForm } from "./SearchForm/SearchForm";
import { useUserContext } from "../../../utils/contexts/UserContext";
import { RegisterCard } from "../../../components/Register/RegisterCard";
import { useReferenceDataContext } from "../../../utils/contexts/ReferenceDataContext";
import { useSearchEditState } from "./useSearchEditState";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { Errors } from "./Errors";
import {
  setSearchCheckedKategori,
  setSearchCheckedSted,
  setSearchFreeText,
  Search,
  SearchParameters,
} from "../../../Types/Search";
import { useLocation } from "react-router-dom";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";

interface SearchPageProps {
  id?: string;
}

const SearchPagePure: FunctionComponent<SearchPageProps> = ({ id }) => {
  let location = useLocation();

  const { error, loading, currentSearch, setCurrentSearch, saveSearch } = useSearchEditState(id);

  const [previewResults, setPreviewResults] = useState<PagedList<AnbudSummary> | undefined>(undefined);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const [showActiveTendersOnly, setShowActiveTendersOnly] = useState(false);
  const [loadedTextFromSearch, setLoadedTextFromSearch] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortField, setSortField] = useState<string>("FileDate"); // Default sortField
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc"); // Default sortOrder
  const { finndoffUser } = useUserContext();
  const { steder, kategorier } = useReferenceDataContext();

  const searchParameters = location.state as SearchParameters;

  useEffect(() => {
    if (loadedTextFromSearch) {
      if (searchParameters) {
        setCurrentSearchParameters(currentSearch, searchParameters);
      }
    }

    !loading &&
      updatePreview(
        currentSearch.searchText,
        currentSearch.stedIds,
        currentSearch.kategoriIds,
        currentSearch.kategoriCPV,
        currentSearch.visAktive,
        currentSearch.noticeType,
        currentSearch.includeParentCodes,
        currentPage,
        sortField,
        sortOrder
      ).catch(console.error);
  }, [
    loading,
    currentSearch.searchText,
    currentSearch.kategoriIds,
    currentSearch.kategoriCPV,
    currentSearch.stedIds,
    location.state,
    currentSearch.visAktive,
    currentPage,
    currentSearch.noticeType,
    currentSearch.includeParentCodes,
    sortField,
    sortOrder,
  ]);

  const { post: brukerdefinertSokPost, response: previewResponse, error: previewError, abort } = useApi(
    "/api/brukerdefinertsok"
  );

  const setCheckedKategori = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedKategori(currentSearch, kategorier, id, checked));

  const setCheckedSted = (id: number, checked: boolean) =>
    setCurrentSearch(setSearchCheckedSted(currentSearch, steder, id, checked));

  const setCurrentSearchParameters = (currentSearch: Search, searchParameters: SearchParameters) => {
    setCurrentSearch(
      setSearchFreeText(
        currentSearch,
        searchParameters.freeText ? searchParameters.freeText : currentSearch.searchText,
        searchParameters.kategorier ? searchParameters.kategorier : currentSearch.kategoriIds,
        searchParameters.steder ? searchParameters.steder : currentSearch.stedIds,
        searchParameters.noticeTypes ? searchParameters.noticeTypes : currentSearch.noticeType
      )
    );
    setLoadedTextFromSearch(false);
  };

  const setCurrentSearchPage = (currentPage: number) => {
    setCurrentPage(0);
    return currentPage;
  };

  const updatePreview = async (
    searchText: string,
    checkedStedIds: Set<number>,
    checkedKategoriIds: Set<number>,
    checkedKategoriCPV: Set<number>,
    TendersOnly: boolean,
    noticeType: string,
    includeParentCodes: boolean,
    currentPage: number,
    sortField: string | null,
    sortOrder: "asc" | "desc"
  ) => {
    setPreviewIsLoading(true);
    setPreviewResults(undefined);
    abort();

    const results = await brukerdefinertSokPost("/action/previewResult", {
      CpvKodeIds: Array.from(checkedKategoriIds),
      CpvKodeCPV: Array.from(checkedKategoriCPV),
      StedIds: Array.from(checkedStedIds),
      NoticeType: noticeType,
      SearchText: searchText,
      CurrentPage: currentPage,
      rowsPerPage: 20,
      includeParentCodes: includeParentCodes,
      SortField: sortField,
      SortOrder: sortOrder,
    });

    if (previewResponse.ok) {
      setPreviewIsLoading(false);
      setPreviewResults(results);
      setLoadedTextFromSearch(false);
      return;
    }

    if (previewError) {
      console.error(previewError);
    }

    setPreviewIsLoading(false);
    setPreviewResults(undefined);
  };

  const nextPage = () => {
    let currentLocalPage = currentPage + 1;
    setCurrentPage(currentLocalPage);
  };

  const previousPage = () => {
    let currentLocalPage = currentPage + -1;
    setCurrentPage(currentLocalPage);
  };

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const resetSorting = () => {
    setSortField("FileDate");
    setSortOrder("desc");
  };

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <Errors errors={[error]} />;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        padding: (theme) => theme.spacing(1),
        marginTop: (theme) => theme.spacing(2),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={3}>
          <SearchForm
            currentSearch={currentSearch}
            setCurrentSearch={setCurrentSearch}
            setActiveTendersOnly={setShowActiveTendersOnly}
            saveSearch={saveSearch}
            setInitCurrentPage={setCurrentSearchPage}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={9}>
          {!finndoffUser?.active && (
            <Box margin={4} style={{ display: "flex", justifyContent: "center" }}>
              <RegisterCard />
            </Box>
          )}

          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Sorter resultater :
            </Typography>
            <Box display="flex" flexGrow={1} alignItems="center">

              {[
                { field: "FileDate", label: "Lagringsdato" },
                { field: "DispatchDate", label: "Publiseringsdato" },
                { field: "LimitDate", label: "Tilbudsfrist" },
                { field: "PriceFixed", label: "Verdi" },
                { field: "Bedriftnavn", label: "Firmanavn" },
              ].map(({ field, label }, index) => (
                <Box key={field} display="flex" alignItems="center">
                  <Button
                    variant="text"
                    onClick={() => handleSortChange(field)}
                    sx={{
                      textTransform: "none",
                      padding: 0,
                      color: sortField === field ? "primary.main" : "text.primary",
                      minWidth: 0,
                      fontWeight: sortField === field ? "bold" : "normal",
                    }}
                    endIcon={
                      sortField === field
                        ? sortOrder === "asc"
                          ? "▲"
                          : "▼"
                        : null
                    }
                  >
                    {label}
                  </Button>
                  {index < 5 && <Typography variant="body1" sx={{ mx: 1 }}> | </Typography>}
                </Box>
              ))}
              <IconButton onClick={resetSorting} sx={{ mr: 2 }} title="Reset Sorting">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <SearchResults
            isLoading={previewIsLoading}
            maybeSearchResponse={previewResults}
            checkedKategoriIds={currentSearch.kategoriIds}
            checkedKategoriCPV={currentSearch.kategoriCPV}
            checkedStedIds={currentSearch.stedIds}
            setCheckedSted={setCheckedSted}
            setCheckedKategori={setCheckedKategori}
            showActiveTendersOnly={showActiveTendersOnly}
          />

          {!loading && finndoffUser?.active && (
            <Grid container direction="row" justifyContent="center" spacing={2}>
              {previewResults !== undefined && currentPage > 0 && (
                <Grid item>
                  <SecondaryButton onClick={previousPage} text={"Forrige"} />
                </Grid>
              )}
              {previewResults !== undefined && currentPage < previewResults.totalPages - 1 && (
                <Grid item>
                  <SecondaryButton onClick={nextPage} text={"Neste"} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export const SearchPage = SearchPagePure;
