export const portalUrl = "/portal";

export const portalPaths = {
  start: "start",
  search: "search",
  searches: "searches",
  searchEdit: "searchEdit/:id",
  profile: "profile",
  about: "about",
  vilkaar: "vilkaar",
  prices: "prices",
  news: "news",
  faq: "faq",
  contact: "contact",
  procurementplanner: "procurementplanner",
  createsupplierbulletin: "finnunderleverandorer/:doffinAnbudId",
  supplierbulletinlist: "underleverandorer",
  supplierbulletindetail: "underleverandorbulletin",
  companies: "companies",
};

export const paths = {
  portal: portalUrl,
  ...portalPaths,
  earlybird: `/earlybird`,
  logout: "/logout",
  login: "/login",
  signup: "/signup",
  industrysignup: "/industrysignup",
  signupresult: "/signupresult",
  tender: "/tender",
  tendersummary: "/tendersummary",
  tnc: "/tnc",
  privacypolicy: "/privacypolicy",
};
