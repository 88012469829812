import React, { useState } from "react";
import { Box, Fab, Fade, Grid, Typography, Zoom } from "@mui/material";
import { SearchCard } from "./SearchCard";
import AddIcon from "@mui/icons-material/Add";
import { useApi } from "../../../utils/hooks/useApi";
import { NavLink } from "react-router-dom";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Search } from "../../../Types/Search";
import { SearchApiResponse, toSearch } from "../Search/useSearchApi";
import { paths } from "../../../app/paths";
import { useUserContext } from "../../../utils/contexts/UserContext";

const SearchesPure: React.FC = () => {
  const { error, loading, data } = useApi("/api/me/brukerdefinertsok", {}, []);
  // const [searches, setSearches] = useState((data as SearchApiResponse[]).map(toSearch));//;

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    const errorMsg = error.toString();
    return (
      <>
        <Typography variant="subtitle1">Noe gikk galt:</Typography>
        <p>{errorMsg}</p>
      </>
    );
  }

  const searches = (data as SearchApiResponse[]).map(toSearch);
  const reloadSearches = () => {
    window.location.reload();
    //   setSearches((data as SearchApiResponse[]).map(toSearch));
  };

  return <SearchesContent initialSearches={searches} reloadSearches={reloadSearches} />;
};
interface SearchesContentProps {
  initialSearches: Search[];
  reloadSearches: () => void;
}

const SearchesContent: React.FC<SearchesContentProps> = ({ initialSearches }) => {
  const [searches, setSearches] = useState(initialSearches);
  const [isInviteLoading] = useState(false);

  const removeSearch = (search: Search) => {
    const newSearches = [...searches];
    newSearches.splice(newSearches.indexOf(search), 1);
    setSearches([...newSearches]);
  };

  const { finndoffUser } = useUserContext();

  return (
    <Box padding={4}>
      <Grid container spacing={6}>
        {(searches as Search[]).map((search, i) => (
          <Fade key={i} in={true} timeout={Math.min(200 * (i + 1), 600)}>
            <Grid item key={i} md={4} sm={6} xs={12}>
              <SearchCard search={search} onDelete={removeSearch} finndoffUser={finndoffUser} />
            </Grid>
          </Fade>
        ))}
        <Zoom in={true} timeout={500}>
          <Grid item style={{ display: "flex", justifyContent: "flex-end" }} xs={12}>
            {!isInviteLoading ? (
              <>
                <NavLink to={`/portal/${paths.search}`} style={{ paddingTop: "7px" }}>
                  <Fab color="secondary" aria-label="add">
                    <AddIcon />
                  </Fab>
                </NavLink>
              </>
            ) : (
              <div>
                <LoadingIndicator />
              </div>
            )}
            {/* comment in when releasing to end user */}
            {/* <HomepageModal
              finndoffUser={finndoffUser}
              setIsGenerationLoading={setIsGenerationLoading}
              setIsAutoSearchDisabled={setIsAutoSearchDisabled}
              reloadSearches={reloadSearches}
              isInviteLoading={isInviteLoading}
            /> */}
          </Grid>
        </Zoom>
      </Grid>
    </Box>
  );
};

export const Searches = withAuthenticationRequired(SearchesPure, {
  onRedirecting: () => <LoadingIndicator />,
});
