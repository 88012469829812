import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

import { useApi } from "../../../utils/hooks/useApi";
import { useUserContext } from "../../../utils/contexts/UserContext";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";
import { useAuth0 } from "@auth0/auth0-react";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";

interface Address {
  adresse: string[];
  postnummer: string;
  poststed: string;
  land: string;
}

interface OrgDetails {
  organisasjonsnummer: string;
  navn: string;
  forretningsadresse: Address;
  postadresse?: Address;
}

const ORG_NUM_LENGTH = 9;

interface RegisterDialogProps {
  open: boolean;
  closeModal: () => void;
}

export const RegisterDialog: React.FC<RegisterDialogProps> = ({ open, closeModal }) => {
  const { user: auth0User, isAuthenticated } = useAuth0();
  const { finndoffUser, setFinndoffUser, isLoading } = useUserContext();

  const { enqueueSuccessMsg, enqueueErrorMsg } = useSnackbar();

  const { post: brukerPost, put: brukerPut } = useApi("/api/me/bruker");

  const [initDone, setInitDone] = useState(false);
  const [orgNum, setOrgNum] = useState("");
  const [orgNumErrorMsg, setOrgNumErrorMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [billingAddressLine1, setBillingAddressLine1] = useState("");
  const [billingAddressLine2, setBillingAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalPlace, setPostalPlace] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState(`${auth0User.given_name} ${auth0User.family_name}`);
  const [email, setEmail] = useState(auth0User.email);
  const [newsletterChosen, setNewsletterChosen] = useState(true);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return null;
  }
  if (!initDone && finndoffUser) {
    setOrgNum((finndoffUser.orgNummer ?? 0) > 0 ? (finndoffUser.orgNummer ?? 0).toString() : "");
    setCompanyName(finndoffUser.firmaNavn);
    setContact(finndoffUser.kontaktperson);
    setEmail(finndoffUser.epostadresse);
    setPhone(finndoffUser.kontaktpersonMobil);
    setBillingAddressLine1(finndoffUser.adresselinje1);
    setBillingAddressLine2(finndoffUser.adresselinje2);
    setPostalCode(finndoffUser.postNummer);
    setPostalPlace(finndoffUser.postSted);
    setNewsletterChosen(finndoffUser.nyhetsbrev);
    setInitDone(true);
  }

  const setOrgDetails = (json: OrgDetails) => {
    setOrgNumErrorMsg("");
    setOrgNum(json.organisasjonsnummer);
    setCompanyName(json.navn);

    let address: Address;
    if (typeof json.postadresse === "object") {
      address = json.postadresse;
    } else {
      address = json.forretningsadresse;
    }
    const addressLines = address.adresse;
    if (addressLines.length > 0) {
      setBillingAddressLine1(addressLines.shift() || "");
    }

    if (addressLines.length > 0) {
      setBillingAddressLine2(addressLines.join(", "));
    } else {
      setBillingAddressLine2("");
    }
    setPostalCode(address.postnummer);
    setPostalPlace(address.poststed);
  };

  const onChangeOrgNum = async (orgIn: string) => {
    const onWrongOrgNum = (_orgNum: string, _status: string) => {
      // Avoid showing error message before the user has finished typing

      if (_orgNum.length !== 9) {
        return;
      }

      if (orgNum.length !== ORG_NUM_LENGTH) {
        setOrgNumErrorMsg("Ikke gyldig orgnummer.");
      }

      setOrgNumErrorMsg("Fant ikke organisasjonsnummeret i Br.reg.");
      setCompanyName("");
      setBillingAddressLine1("");
      setBillingAddressLine2("");
      setPostalCode("");
      setPostalPlace("");
    };

    const orgNumFromInputField = orgIn.replace(/\s/g, "");
    setOrgNum(orgNumFromInputField);

    if (orgNumFromInputField.length === ORG_NUM_LENGTH) {
      setOrgNum(orgNumFromInputField);
      const resp = await fetch("https://data.brreg.no/enhetsregisteret/api/enheter/" + orgNumFromInputField);
      if (!resp.ok) {
        onWrongOrgNum(orgNumFromInputField, resp.statusText);

        return;
      }
      const json = await resp.json();
      if (typeof json === "object" && json.hasOwnProperty("organisasjonsnummer")) {
        setOrgDetails(json);
      }
    }
  };

  const canSave = orgNumErrorMsg === "" && billingAddressLine1 !== "" && postalCode !== "" && postalPlace !== "";

  const save = async () => {
    const updateMethod = finndoffUser ? brukerPut : brukerPost;

    try {
      const user = await updateMethod({
        orgNummer: orgNum,
        firmaNavn: companyName,
        adresselinje1: billingAddressLine1,
        adresselinje2: billingAddressLine2,
        postNummer: postalCode,
        postSted: postalPlace,
        kontaktperson: contact,
        kontaktpersonMobil: phone,
        epostadresse: email,
        nyhetsbrev: newsletterChosen,
      });
      setFinndoffUser(user);
      closeModal();
      enqueueSuccessMsg("Firmaopplysninger lagret");
    } catch (e) {
      enqueueErrorMsg(`Vi kunne ikke lagre firmaopplysningene dine: ${e.toString()}`);
    }
  };

  const closeDialog = () => {
    setInitDone(false);
    closeModal();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="sm"
      onClose={closeDialog}
    >
      <DialogTitle id="alert-dialog-title">{"Endre firmaopplysninger"}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {!finndoffUser && (
              <Typography variant="subtitle1">
                <b>Vi trenger bare litt mer info</b>
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Grid item>
              <InputLabel sx={{ display: "flex" }}>
                Organisasjonsnummer:
                <InputRequired />
              </InputLabel>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                value={orgNum}
                onChange={(e) => onChangeOrgNum(e.target.value)}
                required
                sx={{ width: 350 }}
              />
              <Typography variant="body2">{orgNumErrorMsg}</Typography>
            </Grid>
            {orgNum && companyName && (
              <>
                <Grid item>
                  {companyName && (
                    <>
                      <Grid item marginTop="1rem">
                        <InputLabel>Firmanavn:</InputLabel>
                      </Grid>
                      <Grid item>
                        <TextField
                          variant="standard"
                          type="text"
                          value={companyName}
                          margin="dense"
                          sx={{ width: 350 }}
                          disabled
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item marginTop="1rem">
                  <InputLabel sx={{ display: "flex" }}>
                    Forretningsadresse:
                    <InputRequired />
                  </InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={billingAddressLine1}
                    // onChange={(e) => setBillingAddressLine1(e.target.value)}
                    margin="dense"
                    sx={{ width: 350 }}
                    placeholder="Adresselinje 1"
                    disabled
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={billingAddressLine2}
                    // onChange={(e) => setBillingAddressLine2(e.target.value)}
                    placeholder="Adresselinje 2"
                    margin="dense"
                    sx={{ width: 350 }}
                    disabled
                  />
                </Grid>
                <Grid item marginTop="1rem">
                  <InputLabel>Postnr:</InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={postalCode}
                    // onChange={(e) => setPostalCode(e.target.value)}
                    placeholder="Postnr"
                    margin="dense"
                    sx={{ width: 350 }}
                    disabled
                  />
                </Grid>
                <Grid item marginTop="1rem">
                  <InputLabel>Poststed:</InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={postalPlace}
                    // onChange={(e) => setPostalPlace(e.target.value)}
                    placeholder="Poststed"
                    margin="dense"
                    sx={{ width: 350 }}
                    disabled
                  />
                </Grid>

                <Grid item marginTop="1rem">
                  <InputLabel>Kontaktperson:</InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    placeholder="Fornavn mellomnavn etternavn"
                    margin="dense"
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item marginTop="1rem">
                  <InputLabel>Epostadresse:</InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Epost"
                    margin="dense"
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item marginTop="1rem">
                  <InputLabel sx={{ display: "flex" }}>
                    Mobil:
                    <InputRequired />
                  </InputLabel>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    variant="standard"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Mobil-nummer"
                    margin="dense"
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    label="Jeg ønsker nyhetsbrev fra Finndoff"
                    control={
                      <Checkbox checked={newsletterChosen} onChange={() => setNewsletterChosen(!newsletterChosen)} />
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton text={"Lagre"} onClick={save} disabled={!canSave} />
      </DialogActions>
    </Dialog>
  );
};

const InputRequired = () => {
  return <Box sx={{ color: "primary.main" }}> &nbsp; *</Box>;
};
