import { FunctionComponent } from "react";
import { Box, Container, Grid, Hidden, Typography } from "@mui/material";
import { paths } from "../../app/paths";
import helloImage from "../../images/Hello.svg";
import { SecondaryButton } from "../Buttons/SecondaryButton";

export const RegisterCard: FunctionComponent = () => {
  return (
    <Container
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "8px",
        color: "secondary.main",
      }}
    >
      <Grid container direction="row" margin="1rem">
        <Hidden smDown>
          <Grid item lg={1} xl={1} md={2} sm={3}>
            <Box component="img" src={helloImage} height="128px" />
          </Grid>
        </Hidden>
        <Grid item lg={11} xl={11} md={10} sm={12} xs={12}>
          <Grid container direction="column">
            <Grid item>
              <Box margin="1rem">
                <Typography variant="h5" color="turquoise.light">
                  Vi er Norges beste anbudsvarsling og kan hjelpe deg i gang med å finne relevante kunngjøringer
                  for deg og din bedrift. 
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box margin="1rem">
                <SecondaryButton text="Start din prøveperiode nå" link={paths.signup}></SecondaryButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
